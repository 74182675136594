<template>
  <div class="vue-tempalte">

  </div>
</template>


<script>
import api from '../../api/api';

export default {
  name: 'LogoutPage',
  mounted() {
    api
      .post('token/logout', {
        token: this.$store.getters["Setting/token"]
      });

    this.$store.dispatch('Setting/unSetCustomerData')
    this.$store.dispatch('Setting/unSetClientData')
    this.$store.dispatch('Setting/logout')
    this.$router.replace({path: '/'})
  }
};
</script>

<style scoped>

</style>
